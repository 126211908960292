@import "../../themes/colors.scss";
@import "../../styles/variable";
@import "../../styles/breakpoint.scss";

.mainDiv {
  padding: 16px;
  background-color: white;
  @media screen and (max-width: $mobile) {
    padding: 5px;
  }
  .addBtn {
    background-color: $primaryColor !important;
    color: $backGroundColor_white !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    padding: 6px 18px;
  }
  .disableBtn {
    background-color: #ced4da !important;
    color: $backGroundColor_white !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    padding: 6px 18px;
  }
  .divStyleButton {
    display: flex;
    justify-content: flex-end;
    button {
      white-space: nowrap;
      width: fit-content;
      &:last-child {
        margin-right: 0px !important;
      }
    }
    @media screen and (max-width: '900px') and (min-width: '500px') {
      flex-wrap: wrap;
      gap: 10px;
    }
    @media screen and (max-width: $mobile) {
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;
      padding-bottom: 5px;
    }
  }
  .subHeaderTextfield {
    .MuiInputBase-sizeSmall {
      padding-left: 0;
    }
    input {
      font-weight: 500;
      font-size: 14px;
      font-family: $primaryFont;
      color: #212529;
    }
    .subHeaderTextfield {
      width: 260px;
      height: 37px;
      margin-left: -16px;
      .MuiInputBase-sizeSmall {
        padding-left: 0;
      }
      input {
        font-weight: 500;
        font-size: 14px;
        font-family: $primaryFont;
        color: #212529;
      }
      ::placeholder {
        color: #878a99;
        opacity: 1;
      }
      img {
        margin-top: -1px;
      }
    }
    img {
      margin-top: -1px;
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }
}

.label_style {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #4a4b50;
  padding-left: 4px;
  @media screen and (max-width: $laptop) {
    font-size: 13px;
  }
}

.value_style {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #4a4b50;
  @media screen and (max-width: $laptop) {
    font-size: 13px;
  }
}

.main_styles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}

.textStyle {
  input {
    height: 21px !important;
  }
}

.alignText {
  justify-content: center;
  display: flex !important;
  align-items: center;
  width: 100%;
}

.textStyle {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: #878a99;
  // width: 100%;
  margin-right: 10px;
  white-space: nowrap;
}

.rightTextClass {
  background: #00a751;
  border-radius: 3px;
  padding: 10px 10px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.rightHeadingClass {
  color: #092245;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-right: 10px;
}

.rightTextClassWrapper {
  display: flex;
  align-items: center;
}

.printShippingClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.printShippingTextClass {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #878a99;
  margin-right: 10px;
}

.email_icon_btn {
  // position: absolute !important;
  // right: 0;

  // &::after {
  //   content: "";
    // width: 1px;
    // height: 22px;
    padding: 4px !important;
    border-radius: 2px !important;
    background-color: #00ADF6 !important;
    // position: absolute;
    // left: 0;
  // }
}

.Filter_div{
  width: 454px;
  background-color: white;
  position: absolute;
  border: 1px solid #00A751;
  border-radius: 5px;
  top: 265px;
  right: 85px;
  z-index: 4;
  @media (max-width: 568px) {
    width: 90%;
    height: auto;
    right: 21px;
  }
}

.FilterLabel{
  font-size: 16px;
  font-weight: 500px;
  padding: 15px 20px;
  border-bottom: 1px solid #D9D9D6;
}

.filterSearchIconStyle{
  background-color: #D9D9D9 !important;
  border-radius: 2px !important;
  box-shadow: 0px 1px 3px 0px #00000040, 0px -1px 3px 0px #00000040, 1px 0px 3px 0px #00000040, -1px 0px 3px 0px #00000040 !important;
}

.filterRangeDatePicker {
  position: relative !important;
  z-index: 0 !important;
  width: 100% !important
}

.FilterCustomDateRangeCalender {
  width: 100%;
  span.rs-picker-toggle-placeholder {
    font-size: 13px !important;
  }
  input {
    height: 100% !important;
    font-size: 13px !important;
  }
}