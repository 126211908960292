@import "../../styles/variable";
@import "../../themes/colors.scss";
@import "../../styles/breakpoint";

.tableFooter {
  // position: fixed;
  bottom: 0;
  padding: 20px;
  border-top: 1px solid white;

  .recordShowing {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
}

.tableBody::-webkit-scrollbar {
  height: 10px;
  width: 5px;
}

.tableBody::-webkit-scrollbar-track {
  background: $background-color-white-smoke;
}

.tableBody::-webkit-scrollbar-thumb {
  background: $background-color-hawke-blue;
  border-radius: 15px;
}

.tableHeader {
  position: sticky;
  top: -2px;
  z-index: 1;
}
.common_data_table {
  position: relative;
  thead {
    th {
      padding: 14px;
      position: relative;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-family: $primaryFont;
      min-width: 135px;
      span {
        z-index: 2;
        white-space: nowrap;
        position: relative;
        svg {
          display: none;
        }
        &::after {
          content: "";
          width: 9px;
          height: 13px;
          position: absolute;
          top: 3px;
          right: -20px;
          background: url("../../assets/sorting_icon.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

.NoData {
  width: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.statusColumnsWithKeyStyle {
  padding: 5px 5px;
  background: #00a7511a;
  color: #00a751;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  align-items: center;
  text-align: center;
}

.redStatusColumnsWithKeyStyle {
  padding: 5px 5px;
  background: #f065481a;
  color: #f06548;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  text-align: center;
}

.selectedRowColorClass {
  background: #299cdb0d !important;
}
