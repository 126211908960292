@import "../../../../../themes/colors.scss";

.displayRow {
    display: flex;
    flex-direction: row;
}

.mainPolygonClass{
  padding: 20px;
  transition: transform 0.3s ease;
  transform-origin: left top;
}

.gridInvertedRow{
    display: flex;
    gap: 2px;
    margin-top: 14px;
    justify-content: center;
    width: 100%;
    .rowItemInverted {
      position: relative;
      &:nth-child(2n + 0) {
        margin-top: 5px;
      }
    }
    @media screen and (max-width: '600px') {
      overflow: auto;
      justify-content: flex-start;
    }
}

.polygrame {
    text-align: center;
    min-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;

    .eccentricMargin {
        margin-left: 20px;
    }

    .invertedRowMargin{
        margin-bottom: -10px;
    }
    .invertedmargin {
        margin-top: 10px !important;
    }

    .gridRow{
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: '768px') {
  .polygrame {
    min-width: max-content;
  }
}

.dotButton {
    width: 20px;
    height: 20px;
    margin: 0;
    border: 1px solid #111;
    background-color: $white;
    border-radius: 30px;
    font-size: 10px;
    text-align: center;
    font-weight: bold;
}

.dotButtonInactive {
    width: 20px;
    height: 20px;
    margin: 0;
    background-color: transparent;
    border-radius: 30px;
    // border: 1px solid #111;
    font-size: 10px;
    text-align: center;
    font-weight: bold;
}

.tubeChartPanel {
    border: 1px solid green;
    width: 100%;
    // align-items: center;
    padding: 10px;

    .iconContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: auto;
        width: 100%;
        .panelIcon {
            border: 1px solid black;
            width: 34.5px;
            height: 34.5px;
        }
    }
}

.whiteBackground{
    background-color: white !important;
  }

  .textFieldSize{
    max-width: 200px !important;
  }

  .textStyle{
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
    line-height: 15.73px;
    text-align: left;
  }

  .button_cancel_vessel {
    background-color: #F06548 !important;
    color: white !important;
    font-size: 13px !important;
    font-weight: 500;
    padding: 9px 18px !important;
    text-transform: capitalize !important;
  }

  .vesselDiv{
    padding: 10px;
  }
  
  .arrowPointer {
    cursor: pointer;
  }

  .arrowDisable {
    cursor:not-allowed;
  }