@import "../../themes/colors.scss";


.sub_header_container{
    padding: 5px 24px;
    display: flex;
    align-items: center;
    background: #ffff;
    justify-content: space-between;
    height: 55px;
  }

.sub_heading{
    font-weight: 600;
  font-size: 17px;
  line-height: 34px;
  text-transform: uppercase;
  }
  .print_container{
    display: flex;
    align-items: center;
  }
  .customerID {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 0px 8px;
    color: $color_DUSTY_CHIMNEY;
  }
  
.job_input_div {
    margin: 0px 8px;
  }
  