.color-sketch-picker-container {
  height: 40px;
}

.color-sketch-picker-color-box {
  width: 30px;
  height: 28px;
  border-radius: 6px;
}

.color-sketch-picker-swatch {
  padding: 7px;
  background: #fff;
  display: inline-block;
  cursor: pointer;
}

.color-sketch-picker-popover {
  position: absolute;
  z-index: 2;
}

.color-sketch-picker-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.iconStyle {
  button {
    height: 40px !important;
    border-radius: 0px !important;
    span {
      margin: 0px !important;
    }
  }
}

.containedColor {
  background-color: #00a751 !important;
}

.outlinedColor {
  background-color: #b2e9cc !important;
  span {
    svg {
      fill: black;
    }
  }
}

.EditorHeaderButtonDiv{
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 100%;
  overflow-x: auto;
}

.EditorDeleteBtn{
  display: flex;
  height: 40px;
  margin-left: 10px;
}

.editorStateStyle{
  width: 100%;
  height: 87%;
  margin-bottom: 10px;
  border-radius: 3px;
  max-height: 78vh;
  overflow-y: auto;
  overflow-x: auto;
}